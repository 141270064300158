import replaceString from 'replace-string'

declare global {
    interface Window {
        goBolig: {
            init: typeof init,
        },
    }
}

const API_BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8888/api' : 'https://goboligvaelger.cmhosted.com/api'

type GoBoligHome = {
    numberOfRooms: number,
    floor: string,
    m2: number,
    houseNumber: string,
    rentalPrice: string,
    acceptanceDate: string | null,
    status: 'Ledig' | 'Udlejet' | 'Kontrakt sendt',
    moreInfoUrl: string,
    floorPlanUrl: string,
}

type Home = GoBoligHome & {
    rent: number,
}

const nullHome: Home = {
    rent: 0,
    rentalPrice: '',
    numberOfRooms: 0,
    floor: '-',
    m2: 0,
    houseNumber: '-',
    acceptanceDate: null,
    status: 'Ledig',
    moreInfoUrl: 'https://gobolig.dk',
    floorPlanUrl: 'https://www.gobolig.dk/media/1003/logo.png',
}

const rentalPriceToNumber = (rentalPrice: string) => {
    return rentalPrice
        ? parseInt(replaceString(
            rentalPrice
                .toLowerCase()
                .replace('dkk', ''),
            '.',
            ''
        ))
        : 0
}

const transformHomes = (homes: GoBoligHome[]): Home[] => {
    return homes.map(home => ({
        ...home,
        rent: rentalPriceToNumber(home.rentalPrice)
    }))
}

const getHomesData = async (buildingID: string) => {
    const response = await fetch(`${API_BASE_URL}/RentalOverviewApi/GetRentalsByBuilding?buildingId=${buildingID}`)

    if (!response.ok) {
        console.log(response)
        throw new Error('failed to get homes data')
    }

    return await response.json()
}

const init = (buildingID: string) => {
    return {
        homes: [],
        openHome: nullHome,

        numberOfRoomsValues: [],

        loadingState: 'loading',
        async init() {
            try {
                const numRoomsSet = new Set()

                this.homes = transformHomes(await getHomesData(buildingID))

                for (const { numberOfRooms } of this.homes) {
                    numRoomsSet.add(numberOfRooms)
                }

                this.numberOfRoomsValues = [...numRoomsSet].sort().map(num => num.toString())

                this.loadingState = 'loaded'
            } catch (e) {
                this.loadingState = 'error'
                throw e
            }
        }
    }
}

window.goBolig = {
    init,
}

export default window.goBolig